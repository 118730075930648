<template>
  <v-container class="general">
    <!-- Title -->
    <page-title :component="'GroupSignup'">
      <template v-slot:actions>
        <LanguageSwitcher
          v-if="activeStep !== 'done'"
          ref="languageSwitcher"
          :events="activeStep !== 'confirmation'"
          @click="changeNext('stay')"
          :loading="isLoading"
        />
      </template>
    </page-title>
    <!-- Steps -->
    <AppStepperHeader
      v-model="stepper"
      :steps="steps"
      :loading="isLoading"
    />
    <v-window
      v-model="stepper"
      class="mb-5 no-transition"
    >
      <v-window-item
        v-for="(step, i) in steps"
        :key="`content-${i}`"
        :value="i"
        :class="{ loading: isLoading }"
      >
        <component
          :is="step.component"
          :group-id="groupId"
        />
      </v-window-item>
    </v-window>
    <!-- Actions -->
    <v-btn
      v-if="plugin.id && !isTemplateGroup && activeStep !== 'done'"
      round
      large
      class="ml-0 mr-3 white sw-accent text-none"
      :loading="isLoading"
      @click="deletePlugin"
      >{{ $t("common.delete") }}</v-btn
    >
    <v-btn
      v-if="isTemplateGroup"
      round
      large
      class="ml-0 mr-3 white sw-accent text-none"
      @click="goToSignupList"
      >{{ $t("common.cancel") }}</v-btn
    >
    <v-btn
      v-if="activeStep !== 'done'"
      round
      large
      class="ml-0 mr-3 sw-accent-bg sw-on-accent text-none"
      :loading="isLoading"
      @click="changeNext()"
      >{{ $t("common.continue") }}</v-btn
    >
    <ConfirmationDialog ref="confirmationDialog" />
  </v-container>
</template>

<script>
import moment from "moment-timezone";
import LanguageSwitcher from "@/components/LanguageSwitcher.vue";
import AppStepperHeader from "@/components/AppStepperHeader.vue";
import Settings from "@/components/SignupWizard/Settings.vue";
import SignupQuestions from "@/components/SignupWizard/SignupQuestions.vue";
import EmailConfirmation from "@/components/SignupWizard/EmailConfirmation.vue";
import Menu from "@/components/SignupWizard/Menu.vue";
import Confirmation from "@/components/SignupWizard/Confirmation.vue";
import Done from "@/components/SignupWizard/Done.vue";

export default {
  provide() {
    return {
      parentValidator: this.$validator,
    };
  },
  data: () => ({
    isLoading: false,
    stepper: 0,
    reservedCompleted: {},
    reservedProgress: {},
    reservedPaymentTotal: {},
    audiences: [],
  }),
  computed: {
    groupId() {
      return this.$route.params.group_id;
    },
    groupPluginId() {
      return this.$route.params.group_plugin_id;
    },
    appContentLanguage() {
      return this.$store.getters.appContentLanguage;
    },
    isTemplateGroup() {
      return this.$store.getters.activeGroup.is_template;
    },
    plugin() {
      return this.$store.getters.signupWizardPlugin;
    },
    category() {
      return this.$store.getters.signupWizardCategory;
    },
    attributes() {
      return this.$store.getters.signupWizardAttributes;
    },
    messageTemplate() {
      return this.$store.getters.signupWizardMessageTemplate;
    },
    messageTemplateDefaultBody() {
      if (!this.groupPluginId) return "";
      return (
        `<p>Dear [property name="first_name" user_id]First name[/property]</p>` +
        `<p>Thank you for your registration!</p>` +
        `<p>[payment-overview plugin_id="${this.groupPluginId}" user_id]Payment overview[/payment-overview]</p>` +
        `<p>[group-property property="name" group_id]Event name[/group-property]</p>`
      );
    },
    ionicAppSidemenu: {
      get() {
        return this.$store.getters.signupWizardIonicAppSidemenu;
      },
      set(val) {
        this.$store.dispatch("setSignupWizardIonicAppSidmenu", val);
      },
    },
    appMenuDestination() {
      if (!this.ionicAppSidemenu || !this.ionicAppSidemenu.destinations) {
        return;
      }
      return this.ionicAppSidemenu.destinations.find((el) => el.editable);
    },
    steps() {
      return [
        {
          name: "settings",
          title: this.$t("signup_wizard.settings"),
          component: Settings,
          enabled: true,
        },
        {
          name: "signup_questions",
          title: this.$t("signup_wizard.signup_questions"),
          component: SignupQuestions,
          enabled: true,
        },
        {
          name: "email_confirmation",
          title: this.$t("signup_wizard.email_confirmation"),
          component: EmailConfirmation,
          enabled: true,
        },
        {
          name: "menu",
          title: this.$t("signup_wizard.menu_step_title"),
          component: Menu,
          enabled: true,
        },
        {
          name: "confirmation",
          title: this.$t("signup_wizard.confirmation"),
          component: Confirmation,
          enabled: true,
        },
        {
          name: "done",
          title: this.$t("signup_wizard.done"),
          component: Done,
          enabled: true,
        },
      ].filter((el) => el.enabled);
    },
    activeStep() {
      return this.steps[this.stepper].name;
    },
  },
  components: {
    LanguageSwitcher,
    AppStepperHeader,
  },
  watch: {
    groupPluginId: {
      immediate: true,
      handler() {
        this.fetchAll();
      },
    },
    appContentLanguage: {
      handler(newVal, oldVal) {
        if (newVal === oldVal) return;
        this.fetchAll();
      },
    },
    activeStep: {
      immediate: true,
      handler() {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      },
    },
  },
  methods: {
    stepClass(i) {
      return [
        "pa-3 text-center step",
        {
          complete: i < this.stepper,
          editable: i < this.stepper && this.activeStep !== "done",
          active: i === this.stepper,
        },
      ];
    },
    async changeNext(action) {
      this.errors.clear();

      if (this.activeStep === "settings") {
        const isValid = await this.$validator.validateAll("settings");

        const signupRequiredPrivacyDisclaimer =
          this.plugin.signup_required_privacy_disclaimer;
        const signupPrivacyDisclaimerPlainText = (
          this.plugin.signup_privacy_disclaimer || ""
        ).replace(/<[^>]+>/g, "");

        if (
          signupRequiredPrivacyDisclaimer &&
          !signupPrivacyDisclaimerPlainText
        ) {
          this.errors.add({
            scope: "settings",
            field: "signup_privacy_disclaimer",
            msg: this.$t(
              "signup_wizard.signup_privacy_disclaimer_field_required_error",
            ),
          });

          return;
        }

        if (!isValid) return;

        try {
          this.isLoading = true;

          if (!this.plugin.id) {
            await this.createPlugin();
          } else {
            await this.updatePlugin();
            await this.updateCategory();
          }

          this.isLoading = false;
        } catch (error) {
          if (error) {
            this.isLoading = false;
            this.errorMessageShow(error);
            return;
          }
        }
      }

      if (this.activeStep === "signup_questions") {
        if (!this.attributes.length) {
          this.errors.add({
            msg: this.$t("signup_wizard.no_questions_error"),
            scope: "questions",
            field: "questions",
          });
        }

        if (!this.attributes.some((el) => el.tags.length)) {
          this.errors.add({
            msg: this.$t("signup_wizard.question_migration_required_error"),
            scope: "questions",
            field: "questions",
          });
        }

        if (this.errors && this.errors.items && this.errors.items.length) {
          return;
        }

        try {
          this.isLoading = true;

          await this.updatePlugin();
          await this.createGroupAudiences();
          await this.updateGroup();
          await this.listAttributes();
          await this.atttributeTagList();

          this.isLoading = false;
        } catch (error) {
          if (error) {
            this.isLoading = false;
            this.errorMessageShow(error);
            return;
          }
        }
      }

      if (this.activeStep === "email_confirmation") {
        if (!this.messageTemplate.body && this.messageTemplate.active) {
          this.errors.add({
            msg: this.$t("signup_wizard.body_field_required_error"),
            scope: "message-template",
            field: "body",
          });
        }

        if (this.messageTemplate.active) {
          const isValid = await this.$validator.validateAll("message-template");

          if (!isValid) return;
        }

        try {
          this.isLoading = true;

          if (!this.messageTemplate || !this.messageTemplate.id) {
            await this.createMessageTemplate();
          } else {
            await this.updateMessageTemplate();
          }

          this.isLoading = false;
        } catch (error) {
          if (error) {
            this.isLoading = false;
            this.errorMessageShow(error);
            return;
          }
        }
      }

      if (this.activeStep === "menu") {
        const isValid = await this.$validator.validateAll("menu");

        if (!isValid) return;

        try {
          this.isLoading = true;

          if (!this.appMenuDestination || !this.appMenuDestination.id) {
            await this.createGroupMenuDestination();
          } else {
            await this.updateGroupMenuDestination();
          }

          await this.reorderGroupMenuDestinations();
          await this.updatePlugin();

          this.isLoading = false;
        } catch (error) {
          if (error) {
            this.isLoading = false;
            this.errorMessageShow(error);
            return;
          }
        }
      }

      if (
        (this.activeStep === "confirmation" && action !== "stay") ||
        action !== "stay"
      ) {
        this.stepper += 1;
      } else {
        this.$refs.languageSwitcher.open();
      }
    },
    transformDate(date) {
      if (!date) return null;
      return moment.tz(date, this.userTimezone).format(this.timeFormat);
    },
    async fetchAll() {
      try {
        this.isLoading = true;

        if (this.groupPluginId) {
          await this.getPlugin();
          await this.getCategory();
          await this.listAttributes();
          await this.atttributeTagList();
          await this.listGroupAudiences();
          await this.getMessageTemplate();
          await this.listGroupMenus();
        } else {
          this.$store.dispatch("setSignupWizardPlugin", {
            signup_phone_required: true,
            signup_active_from: moment(),
            signup_active_till: moment().add(1, "days"),
          });
          this.$store.dispatch("setSignupWizardCategory", {});
          this.$store.dispatch("setSignupWizardAttributeTags", []);
          this.$store.dispatch("setSignupWizardAttributes", []);
          this.$store.dispatch("setSignupWizardMessageTemplate", {
            active: 1,
          });
        }

        if (!this.messageTemplate.body) {
          this.messageTemplate.body = this.messageTemplateDefaultBody;
        }

        this.isLoading = false;
      } catch (error) {
        if (error) {
          this.isLoading = false;
        }
      }
    },
    transformPluginForSave(plugin) {
      return {
        name: plugin.name,
        signup_headline: plugin.signup_headline,
        signup_intro_content: plugin.signup_intro_content,
        signup_start_registration_button:
          plugin.signup_start_registration_button,
        signup_thank_you_content: plugin.signup_thank_you_content,
        signup_active_from: plugin.signup_active_from,
        signup_active_till: plugin.signup_active_till,
        signup_closed_content: plugin.signup_closed_content,
        signup_verification_enabled: plugin.signup_verification_enabled,
        signup_phone_required: plugin.signup_phone_required,
        signup_email_domain: plugin.signup_email_domain,
        signup_adminstration_attribute_tags:
          plugin.signup_adminstration_attribute_tags,
        signup_guests_attribute_tags: plugin.signup_guests_attribute_tags,
        signup_required_privacy_disclaimer:
          plugin.signup_required_privacy_disclaimer,
        signup_privacy_disclaimer: plugin.signup_privacy_disclaimer,
        signup_answers_body_text: plugin.signup_answers_body_text,
        signup_answers_fallback_text: plugin.signup_answers_fallback_text,
      };
    },
    transformPluginForEdit(plugin) {
      return {
        id: plugin.id,
        name: plugin.name,
        signup_headline: plugin.attributes
          ? plugin.attributes.signup_headline
          : null,
        signup_intro_content: plugin.attributes
          ? plugin.attributes.signup_intro_content
          : null,
        signup_start_registration_button: plugin.attributes
          ? plugin.attributes.signup_start_registration_button
          : null,
        signup_thank_you_content: plugin.attributes
          ? plugin.attributes.signup_thank_you_content
          : null,
        signup_active_from: plugin.attributes
          ? plugin.attributes.signup_active_from
          : null,
        signup_active_till: plugin.attributes
          ? plugin.attributes.signup_active_till
          : null,
        signup_closed_content: plugin.attributes
          ? plugin.attributes.signup_closed_content
          : null,
        signup_verification_enabled: plugin.attributes
          ? plugin.attributes.signup_verification_enabled
          : false,
        signup_phone_required: plugin.attributes
          ? plugin.attributes.signup_phone_required
          : false,
        signup_email_domain: plugin.attributes
          ? plugin.attributes.signup_email_domain
          : false,
        signup_adminstration_attribute_tags: plugin.attributes
          ? plugin.attributes.signup_adminstration_attribute_tags
          : null,
        signup_guests_attribute_tags: plugin.attributes
          ? plugin.attributes.signup_guests_attribute_tags
          : null,
        signup_required_privacy_disclaimer: plugin.attributes
          ? plugin.attributes.signup_required_privacy_disclaimer
          : false,
        signup_privacy_disclaimer: plugin.attributes
          ? plugin.attributes.signup_privacy_disclaimer
          : null,
        signup_answers_body_text: plugin.attributes
          ? plugin.attributes.signup_answers_body_text
          : null,
        signup_answers_fallback_text: plugin.attributes
          ? plugin.attributes.signup_answers_fallback_text
          : null,
      };
    },
    async createPlugin() {
      const pulginCreateSpecs = [
        this.groupId,
        {
          ...this.transformPluginForSave(this.plugin),
          icon: "fas question",
          prefix: "signup",
          signup_only_new: 0,
          with_attributes: 1,
          lang: this.appContentLanguage,
        },
      ];

      const pluginCreateResponse = await this.$api.groupPlugins.create(
        ...pulginCreateSpecs,
      );

      if (!pluginCreateResponse) return;

      await this.$store.dispatch(
        "setSignupWizardPlugin",
        pluginCreateResponse.data.data,
      );

      this.$router.push({
        name: "signups_edit",
        params: {
          group_id: this.groupId,
          group_plugin_id: pluginCreateResponse.data.data.id,
        },
      });

      await this.createCategory();
    },
    async getPlugin() {
      const params = [
        this.groupId,
        this.groupPluginId,
        {
          lang: this.appContentLanguage,
          with_attributes: 1,
        },
      ];

      const response = await this.$api.groupPlugins.get(...params);

      const pluginData = this.transformPluginForEdit(response.data.data);
      if (!pluginData["signup_privacy_disclaimer"]) {
        pluginData["signup_privacy_disclaimer"] = this.$t(
          "signup_wizard.default_terms_and_condition",
        );
      }
      await this.$store.dispatch("setSignupWizardPlugin", pluginData);
    },
    async updatePlugin() {
      const pulginUpdateSpecs = [
        this.groupId,
        this.plugin.id,
        {
          ...this.transformPluginForSave(this.plugin),
          lang: this.appContentLanguage,
          with_attributes: 1,
        },
      ];

      const pluginUpdateResponse = await this.$api.groupPlugins.update(
        ...pulginUpdateSpecs,
      );

      if (!pluginUpdateResponse) return;

      await this.$store.dispatch(
        "setSignupWizardPlugin",
        this.transformPluginForEdit(pluginUpdateResponse.data.data),
      );

      if (!this.category.id) {
        await this.createCategory();
      }
    },
    async deletePlugin() {
      const isConfirmed = await this.$refs.confirmationDialog.open(
        this.$t("signup_wizard.delete_confirmation_text"),
        this.$t("no"),
        this.$t("yes"),
      );

      if (!isConfirmed) return;

      this.isLoading = true;

      try {
        const params = [this.groupId, this.plugin.id];

        await this.$api.groupPlugins.delete(...params);

        this.isLoading = false;

        this.goToSignupList();
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
        }
      }
    },
    async createCategory() {
      const specs = [
        this.groupId,
        {
          name: this.plugin.name,
          group_plugin_id: this.plugin.id,
        },
      ];

      const response = await this.$api.groupUserAttributeCategories.create(
        ...specs,
      );

      if (!response) return;

      await this.$store.dispatch("setSignupWizardCategory", response.data.data);
    },
    async getCategory() {
      const params = [
        this.groupId,
        {
          group_plugin_id: this.plugin.id,
          per_page: 1,
        },
      ];

      const response = await this.$api.groupUserAttributeCategories.list(
        ...params,
      );

      if (!response) return;

      await this.$store.dispatch(
        "setSignupWizardCategory",
        response.data.data[0],
      );
    },
    async updateCategory() {
      const specs = [
        this.groupId,
        this.category.id,
        {
          name: this.plugin.name,
        },
      ];

      await this.$api.groupUserAttributeCategories.update(...specs);
    },
    async atttributeTagList() {
      const params = [
        this.groupId,
        { group_plugin_id: this.plugin.id, lang: this.appContentLanguage },
      ];

      const response = await this.$api.groupUserAttributeTags.list(...params);

      await this.$store.dispatch(
        "setSignupWizardAttributeTags",
        response.data.data,
      );
    },
    async listAttributes() {
      this.errors.clear();

      const params = [
        this.groupId,
        {
          categories: this.category.id,
          lang: this.appContentLanguage,
          with_reserved: 1,
          per_page: 100,
        },
      ];

      const response = await this.$api.groupUserAttributes.list(...params);

      if (!response) return;

      this.reservedCompleted = response.data.data.find(
        (el) => el.slug === `reserved_g${this.category.id}_completed`,
      );

      this.reservedProgress = response.data.data.find(
        (el) => el.slug === `reserved_g${this.category.id}_progress`,
      );

      this.reservedPaymentTotal = response.data.data.find(
        (el) => el.slug === `reserved_g${this.category.id}_payment_total`,
      );

      const attributes = response.data.data.filter(
        (el) =>
          el.enabled && !el.slug.includes(`reserved_g${this.category.id}`),
      );

      await this.$store.dispatch("setSignupWizardAttributes", attributes);
    },
    async listGroupAudiences() {
      const response = await this.$api.groupAudiences.list(this.groupId, {
        group_plugin_id: this.groupPluginId,
      });

      this.audiences = response.data.data;
    },
    async updateGroup() {
      const specs = [
        this.groupId,
        {
          subscription_product: `Attribute:${this.reservedPaymentTotal.id}`,
        },
      ];

      await this.$api.groups.update(...specs);
    },
    async createGroupAudiences() {
      const audiences = [
        {
          name: "Registration not started",
          filters: [
            {
              property: "subscribed_groups_ids",
              operator: "=",
              value: this.groupId,
            },
            {
              attribute_id: parseInt(this.reservedProgress.id, 10),
              operator: "!exists",
              value: "1",
            },
          ],
        },
        {
          name: "Registration incomplete",
          filters: [
            {
              attribute_id: parseInt(this.reservedProgress.id, 10),
              operator: "<",
              value: "100",
            },
          ],
        },
        {
          name: `Registration completed`,
          filters: [
            {
              attribute_id: parseInt(this.reservedCompleted.id, 10),
              operator: "in",
              value: "1",
            },
          ],
        },
      ];

      for await (const audience of audiences) {
        const isCreated = this.audiences.find(
          (el) => el.name === audience.name,
        );

        if (isCreated) continue;

        const specs = [
          this.groupId,
          { ...audience, group_plugin_id: this.groupPluginId },
        ];

        const response = await this.$api.groupAudiences.create(...specs);

        this.audiences.push(response.data.data);
      }
    },
    async createMessageTemplate() {
      const audience = this.audiences.find(
        (el) => el.name === "Registration completed",
      );

      const specs = [
        this.groupId,
        {
          group_plugin_id: this.plugin.id,
          type: "email",
          name: this.messageTemplate.subject || this.plugin.name,
          subject: this.messageTemplate.subject || this.plugin.name,
          body: this.messageTemplate.body || this.messageTemplateDefaultBody,
          message_image: this.messageTemplate.message_image
            ? this.messageTemplate.message_image.id
            : null,
          send_time_start: this.transformDate(this.plugin.signup_active_from),
          send_time_end: this.transformDate(this.plugin.signup_active_till),
          audiences: audience.id,
          active: this.messageTemplate.active,
          with_attributes: 1,
          lang: this.appContentLanguage,
        },
      ];

      const getMessageTemplateResponse =
        await this.$api.groupMessageTemplates.create(...specs);

      const messageTemplate = getMessageTemplateResponse.data.data;

      if (messageTemplate.message_image) {
        try {
          const mediaGetResponse = await this.$api.media.get(
            messageTemplate.message_image,
          );

          messageTemplate.message_image = mediaGetResponse.data.data;
        } catch (error) {
          if (error) {
            messageTemplate.message_image = null;
          }
        }
      }

      await this.$store.dispatch(
        "setSignupWizardMessageTemplate",
        messageTemplate,
      );
    },
    async getMessageTemplate() {
      const getTemplateParams = [
        this.groupId,
        {
          group_plugin_id: this.plugin.id,
          with_attributes: 1,
          lang: this.appContentLanguage,
          per_page: 1,
        },
      ];

      const getMessageTemplateResponse =
        await this.$api.groupMessageTemplates.list(...getTemplateParams);

      if (!getMessageTemplateResponse) return;

      const messageTemplate = getMessageTemplateResponse.data.data[0];

      if (!messageTemplate) return;

      // get article image

      if (messageTemplate.message_image) {
        try {
          const mediaGetResponse = await this.$api.media.get(
            messageTemplate.message_image,
          );

          messageTemplate.message_image = mediaGetResponse.data.data;
        } catch (error) {
          if (error) {
            messageTemplate.message_image = null;
          }
        }
      }

      await this.$store.dispatch(
        "setSignupWizardMessageTemplate",
        messageTemplate,
      );
    },
    async updateMessageTemplate() {
      const message = this.messageTemplate.active
        ? {
            name: this.messageTemplate.subject || this.plugin.name,
            subject: this.messageTemplate.subject || this.plugin.name,
            body: this.messageTemplate.body || this.messageTemplateDefaultBody,
            message_image:
              this.messageTemplate.message_image &&
              this.messageTemplate.message_image.id
                ? this.messageTemplate.message_image.id
                : null,
            send_time_start: this.transformDate(this.plugin.signup_active_from),
            send_time_end: this.transformDate(this.plugin.signup_active_till),
            lang: this.appContentLanguage,
            active: 1,
          }
        : { active: 0 };

      const specs = [this.groupId, this.messageTemplate.id, message];

      await this.$api.groupMessageTemplates.update(...specs);
    },
    async listGroupMenus() {
      const params = [
        this.groupId,
        { with_disabled: 1, lang: this.appContentLanguage },
      ];

      const response = await this.$api.groupMenus.list(...params);

      const ionicAppSidemenu = response.data.data.find(
        (el) => el.slug === "ionic-app-sidemenu",
      );

      const destination = ionicAppSidemenu.destinations.find(
        (el) =>
          el.component === "QuestionaryReportPage" &&
          el.group_plugin_id == this.groupPluginId,
      );

      if (!destination || !destination.id) {
        ionicAppSidemenu.destinations.push({
          editable: true,
          name: this.$t("signup_wizard.ionic_app_sidemenu_destination_name"),
          description: "",
          icon: "fas question",
          is_public: false,
          enabled: 1,
          promoted: 0,
        });
      } else {
        destination.editable = true;
      }

      this.ionicAppSidemenu = ionicAppSidemenu;
    },
    transformDateForSave(date) {
      return date ? moment(date).format("YYYY-MM-DDTHH:mm:ssZ") : null;
    },
    async createGroupMenuDestination() {
      const specs = [
        this.groupId,
        this.ionicAppSidemenu.id,
        {
          name: this.appMenuDestination.name,
          description: this.appMenuDestination.description,
          icon: this.appMenuDestination.icon,
          component: "QuestionaryReportPage",
          group_plugin_id: this.groupPluginId,
          is_public: this.appMenuDestination.is_public,
          lang: this.appContentLanguage,
          enabled: this.appMenuDestination.enabled ? 1 : 0,
          promoted: this.appMenuDestination.promoted ? 1 : 0,
        },
      ];

      const response = await this.$api.groupMenuDestinations.create(...specs);

      const destination = this.ionicAppSidemenu.destinations.find(
        (el) => el.editable,
      );

      destination.id = response.data.data.id;
    },
    async updateGroupMenuDestination() {
      const specs = [
        this.groupId,
        this.ionicAppSidemenu.id,
        this.appMenuDestination.id,
        {
          name: this.appMenuDestination.name,
          description: this.appMenuDestination.description,
          icon: this.appMenuDestination.icon,
          is_public: this.appMenuDestination.is_public,
          lang: this.appContentLanguage,
          enabled: this.appMenuDestination.enabled ? 1 : 0,
          promoted: this.appMenuDestination.promoted ? 1 : 0,
        },
      ];

      await this.$api.groupMenuDestinations.update(...specs);
    },
    async reorderGroupMenuDestinations() {
      let sortOrder = {};

      for (const [
        i,
        destination,
      ] of this.ionicAppSidemenu.destinations.entries()) {
        sortOrder[destination.id] = i;
      }

      const params = [
        this.groupId,
        this.ionicAppSidemenu.id,
        {
          order: sortOrder,
        },
      ];

      await this.$api.groupMenuDestinations.reorder(...params);
    },
    goToSignupList() {
      this.$router
        .push({
          name: "signups",
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.loading {
  opacity: 0.4;
  transition: all 0.2s;
  pointer-events: none;
}
</style>
